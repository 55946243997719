video {
    width: 300px;
    height: 300px;
    border-radius: 50%; /* Makes the video preview oval */
    border: 5px solid #4CAF50;
    object-fit: cover;
  }

  canvas {
    display: none; /* Hide the canvas element */
  }

  #result {
    margin-top: 20px;
    font-weight: bold;
  }

  /* button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
  } */

  /* button:hover {
    background-color: #45a049;
  } */